<template>
    <component id="apps" :current="current" :is="userComponent"></component>
</template>
<script>
const Gabarit1 = () => import("./templates/Gabarit1")
const Gabarit2 = () => import("./templates/Gabarit2")

export default {
  name: 'App',
 data() {
    return {
      gabarit : null
    };
  },   
  methods : {
    current(value) {
       console.log(value, '--------')
    }
  },
  computed: {
    userComponent() {
       if(this.$root.configOp.datas.template === 'gabarit1') return Gabarit1;
       if(this.$root.configOp.datas.template === 'gabarit2') return Gabarit2;
    }
  }  
}
</script>